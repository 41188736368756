<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="90px">
        <el-form-item label="手机号码">
          <el-input v-model="searchData.mobile" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="领取时间">
          <el-date-picker
            style="width:250px;"
            size="small"
            v-model="searchData.timeSlot"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="page_size" />
  </div>
</template>

<script>
import { getListAPI,chargesOffAPI } from './api'
const columns = [
  {
    label: 'id',
    prop: 'id',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '会员信息',
    prop: 'user',
    minWidth: '160',
    customRender(h, row) {
      return (
        <div>
          <div>昵称：{row['user']?.['nickname']}</div>
          <div>手机号：{row['user']?.['mobile']}</div>
        </div>
      )
    }
  },
  {
    label: '优惠券名称',
    prop: 'name',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['name']}</p>
    }
  },
  {
    label: '优惠券类型',
    prop: 'coupon_type_desc',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['coupon_type_desc']}</p>
    }
  },
  {
    label: '最低消费金额（元）',
    prop: 'min_price',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['min_price']}</p>
    }
  },
  {
    label: '优惠方式',
    prop: 'identityNo',
    minWidth: '150',
    customRender(h, row) {
      return (
        <div>
          <p v-show={row['coupon_type'] == 10}>减免金额：{row['reduce_price']}</p>
          <p v-show={row['coupon_type'] == 20}>折扣率：{row['discount']}</p>
        </div>
      )
    }
  },
  {
    label: '有效期',
    prop: 'identityNo',
    minWidth: '200',
    customRender(h, row) {
      return (
        <div>
            {row['start_time']} ~ {row['end_time']}
        </div>
      )
    }
  },
  {
    label: '状态',
    prop: 'is_valid',
    width: '160',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-tag size="small" type={row['is_valid']?'parimary':'danger'} >
            {row['is_valid']?'有效':'已核销'}
          </el-tag>
        </div>
      )
    }
  },
  {
    label: '领取时间',
    prop: 'created_at',
    width: '180',
    fixed: 'right',
    customRender(h, row) {
      return <p>{row['created_at']}</p>
    }
  },
  {
    label: '操作',
    minWidth: '160',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link v-show={row['is_use']==0} type="primary" onClick={() => this.handleDelete(row)}>
            核销
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',

  data() {
    return {
      columns,
      currentPage: 1,
      page_size: 10,
      total: 0,
      list: [],
      searchData: {
        mobile: '',
        timeSlot: [],
        start_time: '',
        end_time: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { mobile, timeSlot } = this.searchData
      let params = { page: this.currentPage, mobile }
      params.start_time = timeSlot[0] || ''
      params.end_time = timeSlot[1] || ''
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    handleDelete({ id }) {
       this.$confirm('此操作将永久核销该优惠券, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          chargesOffAPI(id).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '核销成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消核销'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 10px;
        .el-input {
          width: 250px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }
}
</style>
