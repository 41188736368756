import { http } from '@/http/axios.js'
// list
export function getListAPI(params) {
  return http({
    url: '/company/basic/marketing/couponUser/getList',
    method: 'get',
    params
  })
}
// 核销
export function chargesOffAPI(id) {
  return http({
    url: '/company/basic/marketing/couponUser/chargesOff',
    method: 'post',
    data:{id}
  })
}
